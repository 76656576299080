import React, { Fragment, useState } from "react"
import ReactModal from "react-modal"
import Moment from "react-moment"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const SchedulePage = ({ data }) => {
  const [openModal, setOpenModal] = useState(null)

  const handleClick = id => {
    setOpenModal(id)
  }

  const handleKeyDown = (keyCode, id) => {
    if (keyCode === 13) {
      setOpenModal(id)
    }
  }

  const close = () => {
    console.log("updating state to null")
    setOpenModal(null)
  }

  const getLectures = lectureNodes => {
    return lectureNodes.map(lectureNode => {
      const currentTime = new Date()
      const { id, html } = lectureNode.node
      const {
        type,
        speaker,
        start,
        title,
        end,
        image,
        remoteImage,
      } = lectureNode.node.frontmatter
      const lectureEndTime = new Date(end)
      const classes = ["lecture"]
      if (lectureEndTime < currentTime) {
        classes.push("lecture-past")
      }
      const backgroundImageStyle = {
        backgroundImage: `url('${image ? image.publicURL : remoteImage}')`,
      }
      return (
        <Fragment key={id}>
          <ReactModal onRequestClose={close} isOpen={openModal === id}>
            <button className="closeButton" onClick={close}>
              &times;
            </button>
            <div className="single-info-modal">
              <div className="workshop-img-wrap no-workshop-img">
                <div
                  className="workshop-img"
                  style={backgroundImageStyle}
                ></div>
              </div>

              <div className="single-info-main">
                <div className="single-title">{title}</div>
                <div className="single-speaker">w/ {speaker}</div>
                <div className="single-additional">
                  <Moment format="dddd, MMMM D, YYYY - h:mm A">{start}</Moment>{" "}
                  to <Moment format="h:mm A">{end}</Moment>
                </div>
              </div>
              <div
                className="single-info-desc"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </ReactModal>
          <div
            className={classes.join(" ")}
            role="button"
            tabIndex={0}
            onClick={() => type === "talk" ? handleClick(id) : null}
            onKeyDown={ev => type === "talk" ? handleKeyDown(ev.keyCode, id) : null}
          >
            <div className="times">
              <strong>
                <Moment format="h:mm A">{start}</Moment>
              </strong>
              <br />
              <Moment format="h:mm A">{end}</Moment>
            </div>
            <div className="divider"></div>
            <div className="lecture-info">
              <h4 className="schedule-title">{title}</h4>
              <div className="schedule-name-title">{speaker}</div>
            </div>
          </div>
        </Fragment>
      )
    })
  }

  const lectureDays = data.lectures.group.map(lectureDay => {
    const day = lectureDay.fieldValue
    const lectures = getLectures(lectureDay.edges)
    return (
      <Fragment key={day}>
        <h2>
          <Moment format="MMMM DD">{lectureDay.edges.length > 0 ? lectureDay.edges[0].node.frontmatter.start : day}</Moment>
        </h2>
        {lectures}
      </Fragment>
    )
  })

  return (
    <Layout page="ottawa">
      <SEO title="ForwardJS Ottawa 2020 Schedule" />
      <section
        className="hero web-hero large-section thanks has-banner"
        style={{ backgroundImage: `url(${data.bgImage.publicURL})` }}
      >
        <div className="fadeOverlay"></div>
        <div className="inner fadeIn" data-wow-duration="1s">
          <h1>ForwardJS Ottawa 2020 Schedule</h1>
          <p>All times below are shown in YOUR LOCAL TIMEZONE</p>
        </div>
      </section>
      <section className="forward-schedule">
        <section className="schedule-section">
          <br />
          {lectureDays}
        </section>
      </section>
    </Layout>
  )
}

export default SchedulePage

export const query = graphql`
  {
    bgImage: file(relativePath: { eq: "bg-image-masks.jpg" }) {
      publicURL
    }
    lectures: allMarkdownRemark(
      sort: { fields: frontmatter___start }
      filter: {
        frontmatter: {
          type: { in: ["talk","break"] }
          conference: { eq: "ottawa" }
          day: { ne: null }
        }
      }
    ) {
      group(field: frontmatter___day) {
        fieldValue
        totalCount
        edges {
          node {
            id
            frontmatter {
              image {
                publicURL
              }
              type
              company
              github
              remoteImage
              position
              title
              speaker
              tracks
              twitter
              imageVertical
              day
              start
              end
            }
            html
          }
        }
      }
    }
  }
`
